import get from "lodash/get";
import { arrInvalid, objectInvalid } from "../../../helper";
import { WAREHOUSE_VALUES } from "../../../constants";

export function getVariantsByWarehouse(warehouses, variants) {
  if (arrInvalid(variants) || arrInvalid(warehouses)) return variants;

  // validate warehouse
  const newWh = warehouses.filter((item) => WAREHOUSE_VALUES.includes(item));
  if (arrInvalid(newWh)) return variants;

  const mapVariants = [];
  for (let va of variants) {
    if (
      objectInvalid(va) ||
      !va.fulfillmentWarehouses ||
      arrInvalid(va.fulfillmentWarehouses)
    )
      continue;

    if (va.fulfillmentWarehouses.some((item) => warehouses.includes(item))) {
      mapVariants.push(va);
    }
  }

  return mapVariants;
}

export function mapVariantsByWarehouseByBase(selectedItem) {
  const variants = get(selectedItem, "variants");

  const newVariants = [];

  for (let va of variants) {
    const bVariant = get(va, "productBaseVariant");
    const fulfillmentWarehouses = get(bVariant, "fulfillmentWarehouses");
    if (!arrInvalid(fulfillmentWarehouses)) {
      va.fulfillmentWarehouses = fulfillmentWarehouses;
    }

    newVariants.push(va);
  }

  return newVariants;
}

export function mapVariantsByWarehouseByTemplate(template) {
  const base = get(template, "productBases");

  
}