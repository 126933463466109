import get from "lodash/get";
import {
  arrInvalid,
  convertObjectToParams,
  convertToPaged,
  getFullName,
  isPsdByKey,
  strToSlug,
} from "../../../helper";
import {
  ATTR_SIZE_SYNTAX,
  ORDER_STATUS,
  PRODUCT_BASE_TITLE_SYNTAX,
  PRODUCT_SKU_SYNTAX,
  TASK_TYPE,
} from "../../../variable";

export const HAS_ASSIGN_TO_BOOL = {
  assigned: true,
  "un-assigned": false,
};

export const ORDER_TYPE_TO_BOOL = {
  personalized: true,
  normal: false,
};

const LIKE_BOOL_TO_BOOL = {
  yes: true,
  no: false,
};

export const getArrValue = (filter, key) => {
  if (!filter[key]) return;
  return filter[key].split(",").filter(Boolean);
};

export const getBoolValue = (filter, key) => {
  if (filter[key] == null) return;
  return LIKE_BOOL_TO_BOOL[filter[key]];
};

export const getFilterValues = (filter, keys, fn) => {
  for (const key of keys) {
    const values = fn(filter, key);
    filter[key] = values;
  }
};

export const convertTypeHasAssign = (status) => {
  const res = {};
  if (HAS_ASSIGN_TO_BOOL[status] != null) {
    res.hasAssign = HAS_ASSIGN_TO_BOOL[status];
    res.status = ORDER_STATUS.PendingDesign;
  } else {
    res.hasAssign = null;
    res.status = status;
  }

  return res;
};

const isTrue = (val) => val === true;
const isFalse = (val) => val === false;

const convertBoolToStr = (val) =>
  isTrue(val) ? "yes" : isFalse(val) ? "no" : null;

const convertFromBool = (val) => (valTruthy, valFalsely) =>
  val === true ? valTruthy : val === false ? valFalsely : null;

export const setParamFromFilter = (filter) => {
  let {
    personalized,
    hasDesign,
    usingTaskBulkDesign,
    usingDesignApp,
    hasPBArtwork,
    usingExpressShipping,
    limit,
    offset,
    hasAssign,
    fieldByTime,
    ...rest
  } = filter || {};
  let paged = convertToPaged(limit, offset);
  let orderType = convertFromBool(personalized)("personalized", "normal");
  hasAssign = convertFromBool(hasAssign)("assigned", "un-assigned");

  hasDesign = convertBoolToStr(hasDesign);
  usingTaskBulkDesign = convertBoolToStr(usingTaskBulkDesign);
  usingDesignApp = convertBoolToStr(usingDesignApp);
  hasPBArtwork = convertBoolToStr(hasPBArtwork);
  usingExpressShipping = convertBoolToStr(usingExpressShipping);

  const params = convertObjectToParams({
    limit,
    paged,
    orderType,
    hasDesign,
    usingDesignApp,
    hasPBArtwork,
    ...rest,
    hasAssign,
    usingTaskBulkDesign,
    usingExpressShipping,
  });
  return params;
};

export const getCheckAllState = (rows, rowsSelected) => {
  const len = (rows || []).length;
  const selectedLen = (rowsSelected || []).length;

  if (len === selectedLen) return true;
  if (selectedLen > 0) return "indeterminate";
  return false;
};

// checked is `check all` value
export const getRowsSelected = (rows, checked) => {
  if (!checked) return [];
  const ids = (rows || []).filter((item) => item?.id).map((item) => item.id);
  return Array.from(new Set(ids));
};

// id => row id
// rowsSelected => list id selected, e.g: [id1, id2, ...]
export const updateRowState = (id, rowsSelected) => {
  const temp = new Set(rowsSelected);
  if (temp.has(id)) {
    temp.delete(id);
  } else {
    temp.add(id);
  }

  return Array.from(temp);
};

export const getProductID = (task) => {
  const product = get(task, "product");
  const originalProductID = get(task, "order.originalProductID");
  return originalProductID ? originalProductID : product?.id;
};

/**
 * Check conditions if `true` then show label `Has PB Artwork`, otherwise no show.
 *
 * @param {Object} product The product value in task
 * @param {Object} order The order value in task
 * @param {*} productVariantId The productVariantId value in task
 * @returns {Boolean}
 */
export function checkHasPBArtwork(product, order, productVariantId) {
  if (!product || !order) return null;

  // Product fields
  const pBaseLength = product.productBases?.length;
  const hasPBArtwork = product.hasPBArtwork;
  const basesHasArtwork = product.basesHasArtwork;
  const baseVariantsHasArtwork = product.baseVariantsHasArtwork;

  /* Check product campaign by base length */
  const isCampaign = pBaseLength > 1;

  // Order fields
  const productBaseVariant = order.productVariant?.productBaseVariant;
  const productBaseVariantID = productBaseVariant?.id;
  const productBaseIdInVariant = productBaseVariant?.productBase?.id;

  /**
   * Check `productVariantId` == null => check `baseId` in `basesHasArtwork`,
   * otherwise check `baseVariantId` in `baseVariantsHasArtwork`
   */
  const hasArtwork =
    productVariantId == null
      ? (basesHasArtwork || []).includes(productBaseIdInVariant)
      : (baseVariantsHasArtwork || []).includes(productBaseVariantID);

  // Markup
  return isCampaign ? hasArtwork : !!hasPBArtwork;
}

const replaceArr = ["+", "/", "small", "medium", "large"];
export function renderNasFile(
  nasFileNameSyntax,
  productSku,
  pBaseName,
  pBaseVAOption,
) {
  let nasFileName = nasFileNameSyntax;
  let otherProductSku = productSku;
  replaceArr.forEach((i) => {
    otherProductSku = otherProductSku ? otherProductSku.replace(i, "") : "";
  });

  let otherPBaseName = pBaseName;
  replaceArr.forEach((i) => {
    otherPBaseName = otherPBaseName ? otherPBaseName.replace(i, "") : "";
  });

  let otherPBaseVAOption = pBaseVAOption;
  replaceArr.forEach((i) => {
    otherPBaseVAOption = otherPBaseVAOption
      ? otherPBaseVAOption.replace(i, "")
      : "";
  });

  if (nasFileName != null) {
    nasFileName = nasFileName.replace(PRODUCT_SKU_SYNTAX, otherProductSku);
    nasFileName = nasFileName.replace(
      PRODUCT_BASE_TITLE_SYNTAX,
      otherPBaseName,
    );
    nasFileName = nasFileName.replace(ATTR_SIZE_SYNTAX, otherPBaseVAOption);
    nasFileName = nasFileName.split("-").join("_");
  }
  return nasFileName;
}

export function getOriginProduct(task) {
  const product = get(task, "order.originalProduct");
  const hasPsdInNas = get(product, "hasPsdInNas");
  const sku = get(product, "sku");
  const title = get(product, "title");
  return {
    sku,
    title,
    hasPsdInNas,
  };
}

export function getAssignee(task) {
  const assignee = get(task, "assignee");
  return getFullName(assignee);
}

export function pBaseVAOptionFn(arr = []) {
  return arr
    .map((i) => {
      let option = i && i.option;
      replaceArr.forEach((ii) => {
        option = option?.length > 0 ? option.toLowerCase() : "";
        option = option?.length > 0 ? option.replace(ii, "") : "";
      });
      return option ? strToSlug(option.trim()) : "";
    })
    .filter(Boolean)
    .join("_");
}

const sizeSlug = "size";
export function pBaseVariantAttrMatchSize(attr = []) {
  return attr.filter((i) => [sizeSlug].includes(i.slug));
}

export function formatFiles(files) {
  if (arrInvalid(files)) return [];
  const res = [];
  for (let item of files) {
    const newItem = {};
    if (isPsdByKey(item?.key)) {
      newItem.originUrl = item.url;
      newItem.url = item.thumbnailUrl;
    }

    res.push({ ...item, ...newItem });
  }

  return res;
}

export function splitTaskLabel(label) {
  return (label || "").split("|").filter(Boolean);
}

export function isTaskLabel(arr, target) {
  if (arrInvalid(arr) || !target) return false;
  return arr.includes(target);
}

export function isArtistTask(task) {
  const artistTaskID = get(task, "artistTaskID");
  const taskType = get(task, "taskType");

  return !!artistTaskID || taskType === TASK_TYPE.ArtistTask;
}
