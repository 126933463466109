import {
  Avatar,
  Badge,
  Checkbox,
  DataTable,
  Icon,
  Link,
  Spinner,
  Tooltip,
} from "@shopify/polaris";
import {
  CircleAlertMajorMonotone,
  CircleTickMajorFilled,
  InfoMinor,
} from "@shopify/polaris-icons";
import _, { get } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import amazonLogo from "../../assets/images/amazon-logo.png";
import ebayLogo from "../../assets/images/ebay-logo.png";
import etsyLogo from "../../assets/images/etsy-logo.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import shopbaseLogo from "../../assets/images/shopbase.png";
import shopifyLogo from "../../assets/images/shopify.png";
import tiktokLogo from "../../assets/images/tiktok-logo.png";
import woocommerceLogo from "../../assets/images/wooCommerce.png";
import { CUSTOM_FULFILLMENT, PRINTIFY_SLUG } from "../../constants";
import { useAppContext } from "../../context";
import {
  checkRole,
  elementContains,
  getParam,
  NumberToFixed,
  throttle,
} from "../../helper";
import {
  ORDER_STATUS,
  ORDER_STATUS_COLORS_POLARIS,
  PLATFORM_ETSY,
  PLATFORMS,
  PRINTIFY_SHIPPING_SERVICES,
  TASK_STATUS_COLORS_POLARIS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import {
  findAncestor,
  MetaDataWrap,
  PersonalizeWrap,
  ProductDataWrap,
} from "../mapping_order/TableMOPolaris";
import ModalImageClaimPolaris from "../seller/ModalImageClaimPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import SkeletonText from "../shared/SkeletonText";
import SuspenseComp from "../shared/SuspenseComp";
// import SuspenseComp from "../shared/SuspenseComp";
// import { ADDITIONAL_PRICE_TYPE } from "../base/ProductBaseForm";
import { BulkActions } from "./actions/BulkActions";
import { PersonalizedPolaris } from "./actions/PersonalizedPolaris";
import { useAddAcceptedBy } from "./hooks/useAddAcceptedBy";
import { OrderActionsPolaris } from "./OrderActionsPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const DesignFiles = React.lazy(() => import("./components/DesignFiles"));
const Asssignee = React.lazy(() => import("./components/Assignee"));
const ViewTrackingV2 = React.lazy(() =>
  import("../tracking/components/ViewTrackingV2"),
);

const PRINTIFY_SHIPPING_SERVICES_OB = PRINTIFY_SHIPPING_SERVICES.reduce(
  (acc, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {},
);

function getImages(images, condition) {
  const result = [];

  for (let i = 0; i < images.length; i++) {
    const img = images[i];
    if (condition(img, i, images)) {
      result.push(img);
    }
  }

  return result;
}

export function checkImages(product, pBaseId) {
  if (!product) return [];
  const mainImageId = product?.mainImageId;
  const isCampaign = product?.productBases?.length > 1;
  const images = product?.images;

  const length = images == null ? 0 : images.length;
  if (!length) return [];

  // Has main image
  const hasMainImageId = mainImageId && mainImageId !== "undefined";

  // Get main image by field `mainImageId`.
  let mainImage = hasMainImageId
    ? getImages(images, (i) => i?.file?.id === mainImageId)
    : [];

  // Get image not main image
  let imagesNormal = getImages(images, (i) => i?.file?.id !== mainImageId);

  /** If product campaign then get image by `productBaseId` */
  if (pBaseId && isCampaign) {
    let imageByBase = getImages(
      imagesNormal,
      (i) => i?.productBaseId === pBaseId,
    );

    if (imageByBase.length > 0) {
      imagesNormal = imageByBase;
    }
  }

  // Sort images.
  return [...mainImage, ...imagesNormal].map((i) => i.file);
}

function checkedDesignByQC(product) {
  if (!product) return null;

  // Product fields
  const pBase = product.productBases;
  const pBaseIds = pBase?.length > 0 ? pBase.map((base) => base.id) : [];

  const pBaseLength = pBase?.length;
  const designCheckedByQC = product.designCheckedByQC;
  const basesDCheckedByQC = product.basesDesignCheckedByQC;
  // const baseVDCheckedByQC = product.baseVariantsDesignCheckedByQC;
  // productVariant

  // Check product campaign by base length
  const isCampaign = pBaseLength > 1;

  const hasLabel = pBaseIds.some((id) =>
    (basesDCheckedByQC || []).includes(id),
  );

  return isCampaign ? hasLabel : !!designCheckedByQC;
}

export const OrdersDTPolaris = (props) => {
  // State
  const [columnContentTypes, setColumnContentTypes] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [rows, setRows] = useState([]);
  const [itemsChecked, setItemsChecked] = useState({});
  const [idsChecked, setIdsChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);
  const [originIds, setOriginIds] = useState([]);

  // Props
  let {
    userRole,
    teamRole,
    role,
    filter,
    onSelect,
    updateCount,
    setFilter,
    changeStatusCount,
    fixedColumn,
    screenOptions,
    dataSO,
    refetch,
  } = props;

  useAddAcceptedBy({
    screenOptions,
    data: props.data,
    wrapRef: wrapRef.current,
  });

  // Context
  const { currentUser } = useAppContext();
  const mergeParam = getParam(currentUser);
  let teamSellerLead = false;
  const { isSeller, isMarketplaceManager, isDesignLeader, isSupplier } =
    checkRole(currentUser);
  const isSellerOrMM = isSeller || isMarketplaceManager;

  if (userRole && teamRole) {
    if (userRole.includes(USER_ROLE.Seller)) {
      if (
        [
          TEAM_ROLE.Admin,
          TEAM_ROLE.StoreManager,
          TEAM_ROLE.MarketplaceManager,
        ].includes(teamRole)
      ) {
        teamSellerLead = true;
      }
    }
  }

  useEffect(() => {
    if (onSelect) {
      onSelect(idsChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsChecked]);

  useEffect(() => {
    setItemsChecked([]);
  }, [updateCount, filter.status]);

  const handleCheckedAll = useCallback(
    (newChecked) => {
      setCheckedAll(newChecked);
      let propsOrders =
        props.data && props.data.orders && props.data.orders.nodes;
      if (newChecked) {
        let items = {};
        for (let i = 0; i < propsOrders.length; i++) {
          items = {
            ...items,
            [propsOrders[i].id]: true,
          };
        }
        setItemsChecked(items);
      } else {
        setItemsChecked({});
      }
    },
    [props.data],
  );

  //
  const checkAllMarkup = React.useMemo(
    () => (
      <Checkbox
        label=""
        checked={checkedAll}
        onChange={(newChecked) => handleCheckedAll(newChecked)}
      />
    ),
    [checkedAll, handleCheckedAll],
  );

  useEffect(() => {
    let newHeadings = [];
    let newCol = [];
    let originSO = JSON.parse(JSON.stringify(dataSO)) || [];

    const mappingOrderIndex = originSO.findIndex(
      ({ value }) => value === "mappingOrder",
    );
    if (mappingOrderIndex > -1) {
      originSO.splice(mappingOrderIndex, 1);
      const index = originSO.findIndex(
        ({ value }) => value === "productVariant",
      );

      if (index > -1) {
        originSO.splice(index + 1, 0, {
          value: "mappingOrder",
          label: "Product Data",
          priority: 16,
          public: true,
        });
        originSO.splice(index + 2, 0, {
          value: "mappingOrder",
          label: "Meta Data",
          priority: 16,
          public: true,
        });
      }
    }

    let currentHeadings = originSO.filter(
      (i) => screenOptions.length && screenOptions.includes(i.value),
    );
    newHeadings = currentHeadings.map((i) => i.label);
    newCol = currentHeadings.map(() => "text");

    if ([USER_ROLE.Admin].includes(role) || isSellerOrMM) {
      newCol = ["text", ...newCol];
      newHeadings = [checkAllMarkup, ...newHeadings];
    }
    setColumnContentTypes(newCol);
    setHeadings(newHeadings);
  }, [screenOptions, role, checkAllMarkup, dataSO, isSellerOrMM]);

  useEffect(() => {
    if (props?.data?.orders?.nodes?.length > 0) {
      const nodes = props.data.orders.nodes || [];
      const countRepeat = (nodes || []).reduce((acc, item) => {
        if (!item || item.originId == null) return acc;
        acc[item.originId] = (acc[item.originId] || 0) + 1;
        return acc;
      }, {});

      let newRows = props.data.orders.nodes.map((record) => {
        // Order id
        let supplier = _.get(record, "supplier", null);
        let fulfillmentId = _.get(record, "fulfillmentOrders[0]");
        let fulfillmentSlug = _.get(record, "fulfillment.slug", null);
        const isCustomFulfillment = fulfillmentSlug === CUSTOM_FULFILLMENT;
        if (
          ["scalablepress", "gearment", "printway"].includes(fulfillmentSlug)
        ) {
          fulfillmentId = fulfillmentId && fulfillmentId.fulfillmentSubmitID;
        } else {
          fulfillmentId = fulfillmentId && fulfillmentId.id;
        }

        // Product
        let product = _.get(record, "product", null);
        let productSku = _.get(record, "product.sku", null);

        let originProductId = _.get(record, "originalProductID", null);
        let originProduct = _.get(record, "originalProduct", null);
        let originProductSKU = originProduct && originProduct.sku;
        let originProductTitle = originProduct && originProduct.title;

        let originalId;
        let originalTitle;
        if (product) {
          originalId = originProductId ? originProductId : product.id;
          originalTitle = originProductTitle
            ? originProductTitle
            : product.title;
        }
        let originalSku = originProductSKU ? originProductSKU : productSku;

        let productUrl = null;
        // originalProductIsCampaign
        const originalProductIsCampaign = record?.originalProductIsCampaign;
        if (originalProductIsCampaign) {
          const link = `/${mergeParam}/products/campaign`;
          productUrl = `${link}/${originalId}`;
        } else {
          if (product && product.productBases) {
            const link =
              product.productBases.length > 1
                ? `/${mergeParam}/products/campaign`
                : `/${mergeParam}/products/edit`;
            productUrl = `${link}/${originalId}`;
          }
        }

        let pbVariants = _.get(
          record,
          "productVariant.productBaseVariant",
          null,
        );
        let pbVAttributes = _.get(pbVariants, "attributes", []);
        let pbVBTitle = _.get(pbVariants, "productBase.title");
        let productBases = _.get(product, "productBases", []);
        let shippingRate = _.get(record, "shippingRate", null);
        // let shippingCost = _.get(record, "shippingCost", 0);

        // productBases
        let pBases = _.get(product, "productBases", []);
        let productBaseMarkup =
          pBases && pBases.length > 0
            ? pBases.map((i) => i.title).join(", ")
            : null;

        // Cost
        let costMarkup = null;
        let { currentCost, shippingCost, supplierCost } = getBaseCost(record);

        costMarkup = [USER_ROLE.Supplier].includes(role)
          ? supplierCost
          : currentCost;
        costMarkup = NumberToFixed(costMarkup, 2);
        costMarkup = `$${costMarkup}`;

        // Shipping cost
        let shippingCostMarkup = null;
        let newShippingCost = 0;
        if (null !== shippingCost || undefined !== shippingCost) {
          newShippingCost = NumberToFixed(shippingCost, 2);
        }

        let ff = _.get(record, "fulfillment.slug", null);
        let unCalculated = null;
        if (ff && ["customcat", "gearment"].includes(ff)) {
          if (newShippingCost === 0) {
            unCalculated = "-";
          }
        }

        const isPrintify = ff === PRINTIFY_SLUG;
        shippingCostMarkup = unCalculated
          ? unCalculated
          : `$${newShippingCost}`;

        // Revenue
        let platform = _.get(record, "store.platform", null);
        let revenue = _.get(record, "revenue", 0);
        let newRevenue = 0;
        if (null !== revenue || undefined !== revenue) {
          newRevenue = revenue;
        }
        let tempRevenueEl = "";
        if (newRevenue > 0) {
          newRevenue = newRevenue.toFixed(2);
          switch (platform) {
            case PLATFORMS.Amazon:
              const getAmazonTemp = () => (
                <Tooltip
                  content="Temporary revenue(include Amazon fee)."
                  preferredPosition="above"
                >
                  <Icon source={CircleAlertMajorMonotone} />
                </Tooltip>
              );

              if (record.orderGrand && !record.orderGrand.isUpdated) {
                tempRevenueEl = getAmazonTemp();
              } else if (!record.isFinalRevenue) {
                tempRevenueEl = getAmazonTemp();
              } else {
                tempRevenueEl = (
                  <Tooltip content="Final revenue" preferredPosition="above">
                    <Icon source={CircleTickMajorFilled} color="green" />
                  </Tooltip>
                );
              }
              break;
            case PLATFORMS.Tiktok:
              const getTemp = () => (
                <Tooltip content="Temporary revenue" preferredPosition="above">
                  <Icon source={CircleAlertMajorMonotone} />
                </Tooltip>
              );

              if (!record.isFinalRevenue) {
                tempRevenueEl = getTemp();
              } else {
                tempRevenueEl = (
                  <Tooltip content="Final revenue" preferredPosition="above">
                    <Icon source={CircleTickMajorFilled} color="green" />
                  </Tooltip>
                );
              }
              break;
            default:
              break;
          }
        }

        // Store
        let fulfillment = _.get(record, "fulfillment", null);
        let fulfillmentName = fulfillment && fulfillment.name;
        if (!fulfillmentName && supplier) {
          fulfillmentName = "Merch Bridge";
        }

        let store = _.get(record, "store", null);
        let nameStore = _.get(store, "title", null);
        let srcLogoStore = {
          woocommerce: woocommerceLogo,
          shopify: shopifyLogo,
          ebay: ebayLogo,
          amazon: amazonLogo,
          shopbase: shopbaseLogo,
          etsy: etsyLogo,
          facebook: facebookLogo,
          tiktok: tiktokLogo,
        };

        let styleStore = {
          width: "auto",
          height: "40px",
          marginRight: "0.785rem",
        };

        if (
          ["ebay", "facebook", "shopbase", "etsy", "amazon", "tiktok"].includes(
            platform,
          )
        ) {
          styleStore["height"] = "30px";
        }

        // Store manager
        let ownersStore = _.get(store, "owners", []);
        let ownersMarkup = [];
        if (ownersStore && ownersStore.length) {
          ownersMarkup = ownersStore;
        }
        ownersMarkup = ownersMarkup
          .filter((i) => "Owner" === i.role)
          .map((i, idx) => {
            if (i.user) {
              let user = i.user;
              let initials =
                (user.firstName && user.firstName.trim().charAt(0)) ||
                (user.lastName && user.lastName.trim().charAt(0));
              let fullName = `${user.firstName ? user.firstName : ""} ${
                user.lastName ? user.lastName : ""
              }`;
              return (
                <SMContainer key={idx} index={idx}>
                  <Avatar initials={initials} />
                  <span className="tooltip">{fullName}</span>
                </SMContainer>
              );
            }
            return null;
          })
          .filter(Boolean);

        // Fulfillment
        let status = _.get(record, "status", null);
        let fulfillmentStatus = _.get(record, "fulfillmentStatus", null);
        let newStatus =
          [USER_ROLE.Seller, USER_ROLE.Admin].includes(role) &&
          !isSupplier &&
          [
            ORDER_STATUS.ReadyToPrint,
            ORDER_STATUS.Printing,
            ORDER_STATUS.PendingPayment,
          ].includes(status)
            ? ORDER_STATUS.InProduction
            : status;

        const resultStatus =
          newStatus &&
          ORDER_STATUS_COLORS_POLARIS.find((o) => o.name === newStatus);
        let ffStatus;
        if (fulfillmentStatus) {
          ffStatus = ORDER_STATUS_COLORS_POLARIS.find(
            (o) => o.name === fulfillmentStatus,
          );
        }

        // Task status
        let taskStatus = _.get(record, "taskStatus", null);
        const resultTaskStatus =
          taskStatus &&
          TASK_STATUS_COLORS_POLARIS.find((o) => o.name === taskStatus);

        // Bulk accept design
        const usingTaskBulkDesign = record?.usingTaskBulkDesign;
        const labelAD = usingTaskBulkDesign ? "Yes" : "No";
        const statusAD = usingTaskBulkDesign ? "success" : "critical";
        const bulkADMarkup =
          usingTaskBulkDesign != null ? (
            <div className="bulk-accept-design">
              <Badge children={labelAD} status={statusAD} />
            </div>
          ) : null;

        // Product image
        const pBaseId = get(
          record,
          "productVariant.productBaseVariant.productBase.id",
        );
        let mainImages = checkImages(product, pBaseId);

        // Collection
        let collections =
          product?.collections?.length > 0
            ? product.collections.map((c) => c.name)
            : [];
        const collectionMarkup =
          collections?.length > 0
            ? collections.map((collection, index) => (
                <Badge key={`collection-${index}`} children={collection} />
              ))
            : null;

        // Tag
        let tags =
          product?.tags?.length > 0 ? product.tags.map((c) => c.name) : [];
        const tagMarkup =
          tags?.length > 0
            ? tags.map((tag, index) => (
                <Badge key={`tag-${index}`} children={tag} />
              ))
            : null;

        //usingProductDesign
        let designedMarkup;
        if (checkedDesignByQC(product)) {
          designedMarkup = (
            <div>
              <Badge children="DS files checked by QC" status="info" />
            </div>
          );
        }

        const usingPDMarkup = record?.usingProductDesign ? (
          <div>
            <Badge children="Using product design" status="info" />
          </div>
        ) : null;

        // fulfillmentServiceShippingOption
        let shippingOptionVal = record?.fulfillmentServiceShippingOption;
        if (isPrintify) {
          if (PRINTIFY_SHIPPING_SERVICES_OB[shippingOptionVal]) {
            shippingOptionVal =
              PRINTIFY_SHIPPING_SERVICES_OB[shippingOptionVal];
          }
        }

        const shippingOptionMarkup = (
          <div className="shipping-option-wrap item">
            {!!shippingOptionVal && <Badge children={shippingOptionVal} />}
          </div>
        );

        // Fulfillment config description
        const ffDesc = _.get(
          record,
          "fulfillmentOrders[0].FulfillmentDesc",
          "",
        );
        const ffDescMarkup = ffDesc ? <span>FFDesc: {ffDesc}</span> : null;

        const teamName = get(record, "team.name", "");

        // Product data
        const mappingOrder = record.mappingOrder;
        const productDataMarkup = <ProductDataWrap node={mappingOrder} />;

        // Meta data
        const isPlatformEtsy = [PLATFORM_ETSY].includes(
          platform?.toLowerCase(),
        );
        const metaData = mappingOrder?.metaData;
        const personalized = mappingOrder?.personalized;

        const personalizedMarkup = <PersonalizeWrap data={personalized} />;

        const metaDataMarkup = (
          <div className="meta-data-wrap item">
            <MetaDataWrap data={metaData} />
            {isPlatformEtsy && personalizedMarkup}
          </div>
        );

        // Customter name
        const customer = get(record, "customer");
        let customerName = "";
        if (customer) {
          const f = get(customer, "firstName");
          const l = get(customer, "lastName");
          customerName = [f, l].filter(Boolean).join(" ");
        }

        const customerNameMarkup = (
          <div className="customer-name item">
            <span>{customerName}</span>
          </div>
        );

        const idCls =
          (countRepeat[record.originId] || 0) > 1
            ? "order-id-wrap item flex-custom repeat"
            : "order-id-wrap item flex-custom";

        const columns = [
          ...([USER_ROLE.Admin].includes(role) || isSellerOrMM
            ? [
                {
                  key: true,
                  render: (
                    <div className="selection-wrap">
                      <Checkbox
                        label=""
                        checked={!!itemsChecked[record.id]}
                        onChange={(newChecked) =>
                          handleChecked(record.id, newChecked)
                        }
                      />
                    </div>
                  ),
                },
              ]
            : []),
          {
            key: "orderId",
            render: (
              <div className={idCls}>
                <span className="order-id">
                  {record.id}
                  {record.sellerNote && (
                    <Tooltip content={record.sellerNote}>
                      <Icon source={InfoMinor} />
                    </Tooltip>
                  )}
                </span>
                {fulfillmentId && <span>FFID: {fulfillmentId}</span>}
                {record.ebayOrderId && <span>EBID: {record.ebayOrderId}</span>}
                {ffDescMarkup}
                {record.hasResent && <span>(Resent)</span>}
                <LabelLinkBadge order={record} />
              </div>
            ),
          },
          {
            key: "originId",
            render: (
              <div className="originId-wrap item">
                <span>{record.originId}</span>
              </div>
            ),
          },
          {
            key: "product",
            render: (
              <div className="product-wrap item">
                {product ? (
                  <>
                    <div>
                      <ModalImageClaimPolaris
                        files={{
                          files: mainImages,
                        }}
                        limit={1}
                        width={80}
                        height={80}
                      />
                    </div>
                    <div className="product-detail item flex-custom">
                      {teamSellerLead ||
                      isDesignLeader ||
                      [USER_ROLE.Admin].includes(role) ? (
                        <Link children={originalTitle} url={productUrl} />
                      ) : (
                        <span>{originalTitle}</span>
                      )}
                      {[USER_ROLE.Admin, USER_ROLE.Seller].includes(role) &&
                        record.product &&
                        record.product.personalized && (
                          <PersonalizedPolaris order={record} />
                        )}
                      <div>
                        <Badge children={originalSku} />
                      </div>
                      {usingPDMarkup}
                      {designedMarkup}
                    </div>
                  </>
                ) : (
                  <span>Product does not exists(maybe is deleted)</span>
                )}
              </div>
            ),
          },
          {
            key: "designFiles",
            render: (
              <div className="design-files-wrap item">
                <React.Suspense fallback={<Spinner size="small" />}>
                  <DesignFiles
                    orderId={record?.id}
                    productBaseId={pBaseId}
                    canRequest={screenOptions.includes("designFiles")}
                  />
                </React.Suspense>
              </div>
            ),
          },
          {
            key: "productBases",
            render: (
              <div className="productBase-wrap item flex-custom">
                <span>{productBaseMarkup}</span>
              </div>
            ),
          },
          {
            key: "productVariant",
            render: (
              <div className="pVariants-wrap item flex-custom">
                {productBases && productBases.length > 1 ? (
                  <div>
                    <span className="label">Type: </span>
                    <span style={{ wordBreak: "break-all" }}>{pbVBTitle}</span>
                  </div>
                ) : null}
                {pbVAttributes && pbVAttributes.length > 0
                  ? pbVAttributes.map((att, idx) => (
                      <div key={idx}>
                        <span className="label">{att.name}:</span>{" "}
                        <span style={{ wordBreak: "break-all" }}>
                          {att.option}
                        </span>
                      </div>
                    ))
                  : productBases && productBases.length > 0
                  ? productBases.map(
                      (pb) =>
                        pb.attributes &&
                        pb.attributes.map((att, index) => (
                          <div key={index}>
                            <span className="label">{att.name}:</span>{" "}
                            <span style={{ wordBreak: "break-all" }}>
                              {att.options.join(", ")}
                            </span>
                          </div>
                        )),
                    )
                  : null}
              </div>
            ),
          },
          {
            key: "mappingOrder",
            render: productDataMarkup,
          },
          {
            key: "mappingOrder",
            render: metaDataMarkup,
          },
          {
            key: "buyerName",
            render: customerNameMarkup,
          },
          {
            key: "qty",
            render: (
              <div className="quantity-wrap item">
                <span>{record.quantity}</span>
              </div>
            ),
          },
          {
            key: "cost",
            render: (
              <div className="cost-wrap item">
                <span>{costMarkup}</span>
              </div>
            ),
          },
          {
            key: "shippingCost",
            render: (
              <div className="shippingCost-wrap item flex-custom">
                <span>{shippingCostMarkup}</span>
                <span className="shipping-rate">
                  {shippingRate && shippingRate.length > 0 ? (
                    <span>({shippingRate})</span>
                  ) : null}
                </span>
              </div>
            ),
          },
          {
            key: "revenue",
            render: (
              <div className="revenue-wrap item">
                <span>${newRevenue}</span>
                <span className="revenue-icon">
                  {newRevenue > 0 ? tempRevenueEl : null}
                </span>
              </div>
            ),
          },
          {
            key: "teamName",
            render: (
              <div className="item team-name-wrap">
                <span>{teamName}</span>
              </div>
            ),
          },
          {
            key: "store",
            render: (
              <div className="store-wrap item">
                <div>
                  {platform && (
                    <img
                      alt=""
                      src={srcLogoStore[platform]}
                      style={{ ...styleStore }}
                    />
                  )}
                </div>
                <div>
                  <span>{nameStore}</span>
                </div>
              </div>
            ),
          },
          {
            key: "storeManager",
            render: <div className="storeManager-wrap">{ownersMarkup}</div>,
          },
          {
            key: "fulfillment",
            render: (
              <div className="fulfillment-wrap item">
                <span>{fulfillmentName}</span>
                {isCustomFulfillment && (
                  <div>
                    (
                    {[supplier?.firstName, supplier?.lastName]
                      .filter(Boolean)
                      .join(" ")}
                    )
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "supplier",
            render: (
              <div className="supplier-wrap item">
                <span>
                  {supplier
                    ? `${supplier.firstName} ${supplier.lastName}`
                    : null}
                </span>
              </div>
            ),
          },
          {
            key: "carrier",
            render: (
              <div className="carrier-wrap item">
                <span>{record.carrier && record.carrier.name}</span>
              </div>
            ),
          },
          {
            key: "shippingOption",
            render: shippingOptionMarkup,
          },
          {
            key: "printingDate",
            render: (
              <div className="printingDate-wrap">
                {record.printingDate && (
                  <span>
                    {moment(record.printingDate).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                )}
              </div>
            ),
          },
          {
            key: "createdAt",
            render: (
              <div className="createdAt-wrap item">
                {record.createdAt && (
                  <span>
                    {moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                )}
              </div>
            ),
          },
          {
            key: "tracking",
            render: (
              <div className="tracking-wrap item">
                {/* {record.tracking && (
                  <ViewTrackingPolaris
                    order={record}
                    filter={filter}
                    role={role}
                    refetch={refetch}
                  />
                )} */}
                {/** TODO: */}
                <SuspenseComp>
                  <ViewTrackingV2 order={record} refetch={refetch} />
                </SuspenseComp>
              </div>
            ),
          },
          {
            key: "status",
            render: (
              <div className="status-wrap">
                {resultStatus && !fulfillmentStatus ? (
                  <div>
                    <Badge
                      children={
                        isSeller && resultStatus.name === "Processing"
                          ? "Supplier Processing"
                          : resultStatus.name
                      }
                      status={resultStatus.status}
                      progress={
                        resultStatus.progress ? resultStatus.progress : null
                      }
                    />
                    {resultStatus.name === "Pending Design" &&
                      record.designTaskCreated && (
                        <p
                          style={{
                            marginLeft: "0.3rem",
                          }}
                        >
                          <small>(In design task)</small>
                        </p>
                      )}
                  </div>
                ) : (
                  <div>
                    {fulfillmentStatus ? (
                      <Badge
                        status={ffStatus.status}
                        children={fulfillmentStatus || status}
                      />
                    ) : null}
                  </div>
                )}
                {/* {ORDER_STATUS.Fulfilled === record.status &&
                  record.hasResent && <small>(Resent)</small>} */}
                {record.paused && (
                  <small>(Paused)</small>
                  // <div>
                  //   <Badge status="warning" children="Paused" />
                  // </div>
                )}
              </div>
            ),
          },
          {
            key: "taskStatus",
            render: (
              <div className="task-status_wrap">
                {resultTaskStatus && (
                  <div>
                    <Badge
                      children={resultTaskStatus.name}
                      status={resultTaskStatus.status}
                      progress={
                        resultTaskStatus.progress
                          ? resultTaskStatus.progress
                          : null
                      }
                    />
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "assignee",
            render: (
              <div className="assignee-wrap item">
                <React.Suspense fallback={<SkeletonText />}>
                  <Asssignee orderId={record?.id} />
                </React.Suspense>
              </div>
            ),
          },
          {
            key: "acceptedBy",
            render: (
              <div
                className="accepted-by-wrap item"
                data-order-id={record.id}
              />
            ),
          },
          {
            key: "bulkAcceptDesign",
            render: bulkADMarkup,
          },
          {
            key: "collection",
            render: (
              <div className="collection-wrap item">{collectionMarkup}</div>
            ),
          },
          {
            key: "tag",
            render: <div className="tag-wrap item">{tagMarkup}</div>,
          },
          {
            key: true,
            render: (
              <div className="actions-wrap item">
                <OrderActionsPolaris
                  {...props}
                  value={record}
                  changeStatusCount={changeStatusCount}
                />
              </div>
            ),
          },
        ];
        let isRoleAdmin = [USER_ROLE.Admin].includes(role);
        let columnsChecked = columns
          .filter((i) => screenOptions.includes(i.key) || i.key === true)
          .map((i) => i.render);

        return columnsChecked.length > (isRoleAdmin ? 2 : 1)
          ? [...columnsChecked]
          : [];
      });
      setRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    headings,
    role,
    itemsChecked,
    screenOptions,
    teamSellerLead,
    isDesignLeader,
  ]);

  const handleChecked = useCallback(
    (orderId, newChecked) => {
      let countItemsChecked;
      setItemsChecked((prevState) => {
        let result = { ...prevState, [orderId]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;
        return result;
      });
      if (newChecked) {
        setCheckedAll(true);
      } else if (countItemsChecked === 0) {
        setCheckedAll(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsChecked],
  );

  useEffect(() => {
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domChilds = document.querySelectorAll(".actions-wrap");
    let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

    for (let domParent of domParents) {
      for (let domChild of domChilds) {
        if (elementContains(domParent, domChild)) {
          if (fixedColumn) {
            if (addClass) {
              domParent.classList.add("custom-cell");
            } else {
              domParent.classList.remove("custom-cell");
            }
          } else {
            domParent.classList.remove("custom-cell");
          }
        }
      }
    }

    // Repeat origin id
    const rows = document.querySelectorAll(".Polaris-DataTable__TableRow");
    for (let row of Array.from(rows)) {
      if (row) {
        if (row.style) {
          row.style = "";
        }
        const cells = row.querySelectorAll(".Polaris-DataTable__Cell");
        for (let c of Array.from(cells)) {
          c.style = "";
        }
      }
    }

    const ordersRepeat = document.querySelectorAll(".order-id-wrap.repeat");
    for (let item of Array.from(ordersRepeat)) {
      const row = findAncestor(item, "Polaris-DataTable__TableRow");
      if (row) {
        const cells = row.querySelectorAll(".Polaris-DataTable__Cell");
        for (let c of Array.from(cells)) {
          c.style.backgroundColor = "#ddd";
        }
      }
    }
  }, [rows, coordinates, fixedColumn]);

  // Handle scroll
  useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (wrapRef.current) {
          setCoordinates({
            scrollLeft: wrapRef.current.scrollLeft,
            scrollWidth: wrapRef.current.scrollWidth,
            offsetWidth: wrapRef.current.offsetWidth,
          });
        }
      });
    };

    if (wrapRef.current) {
      wrapRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRef.current.removeEventListener("scroll", handler);
      }
    };
  }, [wrapRef]);

  useEffect(() => {
    let ids = [];
    if (itemsChecked) {
      for (let [key, value] of Object.entries(itemsChecked)) {
        if (value) {
          ids.push(key);
        }
      }
    }
    setIdsChecked(ids);
  }, [itemsChecked]);

  useEffect(() => {
    const nodes = props.data?.orders?.nodes;
    if (!nodes || nodes.length === 0) return;

    const ids = nodes
      .map((node) => {
        const { id, originId } = node || {};
        if (idsChecked.includes(id)) {
          return originId;
        }

        return null;
      })
      .filter(Boolean);

    setOriginIds(ids);
  }, [idsChecked, props.data]);

  const handleCompleted = useCallback(() => {
    setCheckedAll(false);
    setItemsChecked({});
    setIdsChecked([]);
    refetch && refetch();
  }, [refetch]);

  let { limit, offset } = filter;
  const dataProp = props.data;
  const total = _.get(dataProp, "orders.total", 0);
  const aggregations = _.get(dataProp, "orders.aggregations", null);

  const baseCost = _.get(aggregations, "total_base_cost.value", 0);
  const totalRevenue = isSupplier
    ? null
    : _.get(aggregations, "total_revenue.value", 0);
  const totalQty = _.get(aggregations, "total_qty.value", 0);
  const totalOrder = _.get(aggregations, "original_count.value", 0);

  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <React.Fragment>
      {isSellerOrMM && (
        <BulkActions
          idsChecked={originIds}
          ids={idsChecked}
          handleCheckedAll={handleCheckedAll}
          handleCompleted={handleCompleted}
          status={filter?.status}
        />
      )}
      <Container ref={wrapRef}>
        <DataTable
          columnContentTypes={[...columnContentTypes, "numeric"]}
          headings={[
            ...headings,
            <div className="actions-wrap item">Actions</div>,
          ]}
          rows={rows}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris
          aggregation={aggregation}
          showTotal
          {...([USER_ROLE.Seller, USER_ROLE.Admin].includes(role)
            ? { baseCost, totalRevenue, totalQty, totalOrder }
            : null)}
        />
      </PaginationContainer>
    </React.Fragment>
  );
};

// calculate cost & shipping cost vio
function getBaseCost(value) {
  if (value != null) {
    const {
      baseCost,
      quantity,
      supplier,
      carrierPrice,
      shippingCost,
      supplierPrice,
    } = value;
    const hasAdditionalShipping = _.get(
      value,
      "productVariant.productBaseVariant.productBase.hasAdditionalShipping",
      false,
    );
    let currentCost = costWithCarrier(value, "baseCost"); // (baseCost + carrierPrice) * qty
    let newSupplierPrice = costWithCarrier(value, "supplierPrice"); // (supplierPrice + carrierPrice) * qty
    let newShippingCost = shippingCost;

    if (supplier != null && hasAdditionalShipping) {
      currentCost = baseCost * quantity;
      newSupplierPrice = supplierPrice * quantity;
      if (shippingCost === 0) newShippingCost = carrierPrice; // shippingCost == 0 => show carrierPrice
    }

    return {
      currentCost,
      shippingCost: newShippingCost,
      supplierCost: newSupplierPrice,
    };
  }

  return {
    currentCost: 0,
    shippingCost: 0,
    supplierCost: 0,
  };
}

// function checkSameItem(value) {
//   return [ADDITIONAL_PRICE_TYPE].includes(value);
// }

function costWithCarrier(value, price) {
  if (value == null) return 0;
  const { carrierPrice, quantity } = value;
  const newPrice = value[price];

  return (newPrice + carrierPrice) * quantity;
}

function LabelLinkBadge({ order }) {
  const labelLink = get(order, "labelLink");

  if (!labelLink) return null;

  return (
    <CustomLinkPolaris href={labelLink}>
      <Badge status="success" children="Tiktok Label" />
    </CustomLinkPolaris>
  );
}

const Container = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__Cell--header {
    white-space: nowrap;
  }
  .item {
    white-space: normal;
    word-break: break-word;
  }
  .flex-custom {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .order-id-wrap {
    word-break: break-all;
    width: calc(25rem - 3.2rem);
    span:not(.order-id) {
      font-size: 13px;
      opacity: 0.865;
    }

    .order-id {
      display: flex;
      gap: 5px;
    }
  }
  .originId-wrap,
  .assignee-wrap,
  .actions-wrap {
    word-break: break-all;
    width: calc(15rem - 3.2rem);
  }
  .product-wrap,
  .design-files-wrap {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: calc(40rem - 3.2rem);
    .product-detail {
      margin-left: 0.75rem;
    }
  }
  .pVariants-wrap {
    span.label {
      font-weight: 600;
    }
  }
  .quantity-wrap,
  .cost-wrap {
    width: calc(10rem - 3.2rem);
  }

  .accepted-by-wrap {
    width: calc(25rem - 3.2rem);
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .shippingCost-wrap {
    width: calc(25rem - 3.2rem);
    .shipping-rate {
      font-size: 12px;
      font-weight: 500;
      opacity: 0.8;
    }
  }
  .revenue-wrap {
    display: flex;
    .revenue-icon {
      margin-left: 0.5rem;
      .Polaris-Icon {
        display: inline-block;
      }
    }
  }
  .fulfillment-wrap,
  .supplier-wrap,
  .revenue-wrap,
  .pVariants-wrap,
  .collection-wrap,
  .tag-wrap,
  .team-name-wrap,
  .shipping-option-wrap,
  .printingDate-wrap,
  .createdAt-wrap,
  status-wrap,
  .customer-name {
    width: calc(20rem - 3.2rem);
  }
  .store-wrap {
    width: max-content;
    display: flex;
    align-items: center;
  }
  .storeManager-wrap {
    width: calc(21rem - 3.2rem);
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
  }
  .carrier-wrap {
    width: calc(13rem - 3.2rem);
  }

  .tracking-wrap,
  .productBase-wrap {
    width: calc(32rem - 3.2rem);
    small {
      font-size: 87%;
    }
  }

  .actions-wrap {
    margin-left: auto;
  }

  .status-wrap,
  .task-status_wrap {
    small {
      font-size: 87%;
    }
  }

  .product-data-wrap,
  .meta-data-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    width: calc(32rem - 3.2rem);
  }

  .meta-data-wrap img {
    // max-width: 28.8rem;
    max-width: 100px;
    height: auto;
    object-fit: cover;
  }

  .collection-wrap,
  .tag-wrap {
    margin-left: -0.7rem;
    margin-top: -0.9rem;
    > span {
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .Polaris-Badge {
      max-width: 100%;
      .Polaris-Badge__Content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: inherit;
      -webkit-overflow-scrolling: touch;
    }
  }
  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;
      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.custom-cell {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }
    &:last-child {
      .storeManager-wrap {
        padding-bottom: 2rem;
      }
    }
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

const SMContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transform: ${(props) =>
    props.index
      ? `translateX(-${(props.index % 4) * 12}px)`
      : "translateX(0px)"};
  .Polaris-Avatar {
    border: 2px solid #fff;
    width: calc(4rem + 4px);
    .Polaris-Avatar__Svg {
      height: auto;
    }
  }
  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    z-index: 2;
    .tooltip {
      visibility: visible;
    }
  }
`;
