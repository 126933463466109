import { ActionList, Button, Popover } from "@shopify/polaris";
import {
  CircleAlertMajorMonotone,
  CircleCancelMajorMonotone,
  CircleTickMajorMonotone,
  EditMajorMonotone,
  FolderUpMajorMonotone,
  MarkPaidMinor,
  PauseCircleMajorMonotone,
  PlayCircleMajorMonotone,
  RefreshMajorMonotone,
  RepeatOrderMajorMonotone,
  ReplaceMajorMonotone,
  ReportsMajorMonotone,
  SettingsMajorMonotone,
  VocabularyMajorMonotone,
} from "@shopify/polaris-icons";
import { get, isEqual } from "lodash";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { STATUS_DESIGN_TASK } from "../../constants/task";
import { checkRole, objectInvalid } from "../../helper";
import { ORDER_STATUS, TASK_LABEL } from "../../variable";
import { AcceptDesignPolaris } from "./actions/AcceptDesignPolaris";
import { AwaitApproval } from "./actions/AwaitApproval";
import { CancelPolaris } from "./actions/CancelPolaris";
import { CompareDesignPolaris } from "./actions/CompareDesignPolaris";
import { ConfigDesign } from "./actions/ConfigDesign";
import { DeleteNote } from "./actions/DeleteNote";
import { DenyActionPolaris } from "./actions/DenyActionPolaris";
import { DesignResourceFilesPolaris } from "./actions/DesignResourceFilesPolaris";
import { GetSimilarProduct } from "./actions/GetSimilarProduct";
import { MarkAsTaskConfigurable } from "./actions/MarkAsTaskConfigurable";
import { MarkPBError } from "./actions/MarkPBError";
import { MarkPSDinNas } from "./actions/MarkPSDinNas";
import { PassToAwaitingBuyerConfirm } from "./actions/PassToAwaitingBuyerConfirm";
import { PassToNeedReview } from "./actions/PassToNeedReview";
import { ReAssignPolaris } from "./actions/ReAssignPolaris";
import { RejectDesignPolaris } from "./actions/RejectDesignPolaris";
import { RemoveMarkPBError } from "./actions/RemoveMarkFBError";
import { RemoveTaskFromConfigurable } from "./actions/RemoveTaskFromConfigurable";
import { StartActionPolaris } from "./actions/StartActionPolaris";
import { UpdateTaskPolaris } from "./actions/UpdateTaskPolaris";
import { UpdateTaskResource } from "./actions/UpdateTaskResource";
import { UpdateToDoingPolaris } from "./actions/UpdateToDoingPolaris";
import UploadDesignByDesignerPolaris from "./actions/UploadDesignByDesignerPolaris";
import UploadDesignByLeadPolaris from "./actions/UploadDesignByLeadPolaris";
import { ViewDetailPolaris } from "./actions/ViewDetailPolaris";
import { isTaskLabel, splitTaskLabel } from "./utils";
import SuspenseComp from "../shared/SuspenseComp";

const RejectArtistTask = React.lazy(() => import("./actions/RejectArtistTask"));

const Container = styled.div`
  .Polaris-ActionList__Image {
    .Polaris-Icon {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }
  }
`;

export const TasksActionsPolaris = (props) => {
  const {
    task,
    isLeader,
    filter,
    isDesigner,
    onNotificationChange,
    toggleActive,
    refetch,
    isDesignLeader,
    user,
    configDesignRoles,
    canShowTaskConfigurable,
  } = props;

  // Props
  const taskHasTaskSubmission = task && task.hasTaskSubmission;
  const hasAssign = filter && filter.hasAssign;
  const taskStatus = task && task.status;
  const filterStatus = filter?.status;
  const taskPersonalized = task.personalized;
  let compareDesignLabel = "Compare Design";
  if ("Done" === taskStatus) {
    compareDesignLabel = "View Design";
  }

  /** 
   * + Design Leader 
        - cannot `config design` when task assign for other designer
    + Designer
      - can only `config design` when task assign
      */
  const { isOnline: isDesignOnline } = checkRole(user);
  const userId = get(user, "id");
  const assignee = get(task, "assignee");
  const isDesignerBot = checkDesignerBot(assignee);
  const assigneeId = get(task, "assignee.id");
  let canConfig = true;
  if (isDesignerBot) {
    canConfig = true;
  } else {
    if (isDesignLeader && assigneeId) {
      canConfig = false;
    } else if (isDesigner) {
      canConfig = userId === assigneeId;
    }
  }

  if (isDesignOnline) {
    canConfig = false;
  }

  const product = task?.product;
  const isCannotConfigInPB = product?.isCannotConfigInPB;

  // State
  const [popoverActive, setPopoverActive] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Handle actions
  const togglePopover = useCallback(
    () => setPopoverActive((prev) => !prev),
    [],
  );

  const toggleShowModal = useCallback(() => setShowModal((prev) => !prev), []);

  const onAction = useCallback(
    (currentAction) => {
      toggleShowModal();
      setCurrentAction(currentAction);
      setCurrentItemId(() => task.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [task],
  );

  // Get action list
  const actions = [
    {
      content: "View detail",
      icon: VocabularyMajorMonotone,
      status: ["*"],
      role: true,
      onAction: () => onAction("viewDetail"),
    },
    {
      content: compareDesignLabel,
      icon: ReplaceMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Done,
        STATUS_DESIGN_TASK.NeedBuyerConfirm,
        STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
      ],
      role: isLeader,
      onAction: () => onAction("compareDesignUU"),
    },
    {
      content: compareDesignLabel,
      icon: ReplaceMajorMonotone,
      status: [STATUS_DESIGN_TASK.Cancel],
      role: isLeader,
      onAction: () => onAction("compareDesignCancel"),
    },
    {
      content: "View rejected design",
      icon: ReplaceMajorMonotone,
      status: [STATUS_DESIGN_TASK.Rejected],
      role: true,
      onAction: () => onAction("compareDesignRejected"),
    },
    ...(taskHasTaskSubmission
      ? [
          {
            content: "View design",
            icon: ReplaceMajorMonotone,
            status: [STATUS_DESIGN_TASK.Doing],
            role: true,
            onAction: () => onAction("compareDesignDoing"),
          },
        ]
      : []),
    {
      content: "Upload design",
      icon: EditMajorMonotone,
      status: [STATUS_DESIGN_TASK.Doing],
      role: isDesigner,
      onAction: () => onAction("uploadDesignDoing"),
    },
    !isDesignOnline && {
      content: "Set status to Doing",
      icon: RefreshMajorMonotone,
      status: [STATUS_DESIGN_TASK.NeedReview],
      role: isDesigner,
      onAction: () => onAction("updateToDoing"),
    },
    {
      content: "Upload design",
      icon: EditMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.ExpiredSoon,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.Denied,
        STATUS_DESIGN_TASK.Cancel,
      ],
      role: isLeader,
      onAction: () => onAction("uploadDesignByLead"),
    },
    {
      content: "Upload design",
      icon: EditMajorMonotone,
      status: [STATUS_DESIGN_TASK.Done],
      role: isDesignLeader,
      onAction: () => onAction("uploadDesignDone"),
    },
    !isDesignOnline && {
      content: "Accept design",
      icon: CircleTickMajorMonotone,
      status: [STATUS_DESIGN_TASK.NeedReview],
      role: isLeader,
      onAction: () => onAction("acceptDesign"),
    },
    !isDesignOnline && {
      content: [STATUS_DESIGN_TASK.NeedReview].includes(taskStatus)
        ? "Await approval"
        : "Note",
      icon: MarkPaidMinor,
      status: [
        STATUS_DESIGN_TASK.NeedReview,
        ORDER_STATUS.PendingDesign,
        STATUS_DESIGN_TASK.Rejected,
      ],
      role: isLeader,
      onAction: () => onAction("awaitApproval"),
    },
    {
      content: "Pass to Awaiting Buyer Confirm",
      icon: CircleTickMajorMonotone,
      status: [STATUS_DESIGN_TASK.NeedBuyerConfirm],
      role: isLeader,
      onAction: () => onAction("passToAwaitingBuyerConfirm"),
    },
    {
      content: "Pass to Need Review",
      icon: CircleTickMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.NeedBuyerConfirm,
        STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
      ],
      role: isLeader,
      onAction: () => onAction("passToNeedReview"),
    },
    canShowTaskConfigurable &&
      !isDesignOnline && {
        content: "Mark as Configurable",
        icon: CircleTickMajorMonotone,
        status: [STATUS_DESIGN_TASK.Pending],
        role: isLeader,
        onAction: () => onAction("markAsTaskConfigurable"),
      },
    {
      content: "Remove From Configurable",
      icon: CircleTickMajorMonotone,
      status: [STATUS_DESIGN_TASK.ArtworkConfigurable],
      role: isLeader,
      onAction: () => onAction("removeTaskFromConfigurable"),
    },
    {
      content: "Reject design",
      icon: CircleAlertMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.NeedBuyerConfirm,
        STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
      ],
      role: isLeader,
      onAction: () => onAction("rejectDesign"),
    },
    checkRejectAT(task) && {
      content: "Reject Artist Task",
      icon: CircleAlertMajorMonotone,
      status: [STATUS_DESIGN_TASK.Rejected],
      role: isLeader,
      onAction: () => onAction("rejectAristTask"),
    },
    {
      content: "Re-upload design",
      icon: EditMajorMonotone,
      status: [STATUS_DESIGN_TASK.Rejected],
      role: isDesigner,
      onAction: () => onAction("reUploadDesign"),
    },
    ...(null === hasAssign
      ? [
          {
            content: "Start now",
            icon: PlayCircleMajorMonotone,
            status: [STATUS_DESIGN_TASK.Pending],
            role: isDesigner,
            onAction: () => onAction("startNow"),
          },
          {
            content: "Deny",
            icon: PauseCircleMajorMonotone,
            status: [STATUS_DESIGN_TASK.Pending],
            role: isDesigner,
            onAction: () => onAction("deny"),
          },
        ]
      : []),
    canConfig && {
      content: "Config designs",
      icon: SettingsMajorMonotone,
      status: isDesigner
        ? [STATUS_DESIGN_TASK.Doing, STATUS_DESIGN_TASK.Rejected]
        : [
            STATUS_DESIGN_TASK.Pending,
            STATUS_DESIGN_TASK.Rejected,
            STATUS_DESIGN_TASK.ArtworkConfigurable,
          ],
      role: configDesignRoles,
      onAction: () => onAction("config"),
      personalized: true,
    },
    {
      content: "Re-assign",
      icon: RepeatOrderMajorMonotone,
      status: [STATUS_DESIGN_TASK.Denied],
      role: isLeader,
      onAction: () => onAction("reAssign"),
    },
    {
      content: "Update task",
      icon: ReportsMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.ExpiredSoon,
      ],
      role: isLeader,
      onAction: () => onAction("updateTask"),
    },
    {
      content: "Update design resource",
      icon: FolderUpMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.ExpiredSoon,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.Done,
        STATUS_DESIGN_TASK.Cancel,
        STATUS_DESIGN_TASK.Denied,
      ],
      role: true,
      onAction: () => onAction("designResource"),
    },
    {
      content: "Update task resource",
      icon: FolderUpMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.ExpiredSoon,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.Done,
        STATUS_DESIGN_TASK.Denied,
      ],
      role: isDesigner,
      onAction: () => onAction("taskResource"),
    },
    // {
    //   content: "Get similar design",
    //   icon: RefreshMajorTwotone,
    //   status: ["*"],
    //   role: isLeader,
    //   onAction: () => onAction("similarProduct"),
    // },
    !isDesignOnline && {
      content: "Mark has PSD in NAS",
      icon: EditMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.ExpiredSoon,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.Done,
        STATUS_DESIGN_TASK.Cancel,
        STATUS_DESIGN_TASK.Denied,
      ],
      role: ["*"],
      onAction: () => onAction("markPSD"),
    },
    ...(!isDesignOnline && isCannotConfigInPB
      ? [
          {
            content: "Remove Mark PB Error",
            icon: CircleCancelMajorMonotone,
            status: ["*"],
            role: isLeader,
            onAction: () => onAction("removeMarkFBError"),
          },
        ]
      : !isDesignOnline
      ? [
          {
            content: "Mark PB Error",
            icon: EditMajorMonotone,
            status: [
              STATUS_DESIGN_TASK.Pending,
              STATUS_DESIGN_TASK.Doing,
              STATUS_DESIGN_TASK.NeedReview,
              STATUS_DESIGN_TASK.Rejected,
              STATUS_DESIGN_TASK.ExpiredSoon,
              STATUS_DESIGN_TASK.Expired,
              STATUS_DESIGN_TASK.Done,
              STATUS_DESIGN_TASK.Cancel,
              STATUS_DESIGN_TASK.Denied,
            ],
            role: isLeader,
            onAction: () => onAction("markFBError"),
          },
        ]
      : []),
    {
      content: "Delete note",
      icon: CircleCancelMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.NeedReview,
        ORDER_STATUS.Pending,
        STATUS_DESIGN_TASK.Rejected,
      ],
      role: isLeader,
      onAction: () => onAction("deleteNote"),
    },
    !isDesignOnline && {
      content: "Cancel",
      icon: CircleCancelMajorMonotone,
      status: [
        STATUS_DESIGN_TASK.Pending,
        STATUS_DESIGN_TASK.Doing,
        STATUS_DESIGN_TASK.NeedReview,
        STATUS_DESIGN_TASK.Rejected,
        STATUS_DESIGN_TASK.Expired,
        STATUS_DESIGN_TASK.ExpiredSoon,
        STATUS_DESIGN_TASK.Denied,
      ],
      role: isLeader,
      onAction: () => onAction("cancel"),
    },
  ].filter(Boolean);

  let state = actions.filter((a) => {
    return (
      (a.status.includes("*") ||
        a.status.includes(taskStatus) ||
        a.status.includes(filterStatus)) &&
      (a.role || a.role.length) &&
      (a.personalized === undefined || a.personalized === taskPersonalized)
    );
  });

  state = state.map((i) => ({
    content: i.content,
    icon: i.icon,
    onAction: i.onAction,
  }));

  const activator = (
    <Button children="Actions" onClick={togglePopover} disclosure />
  );

  const isNeedReview = [STATUS_DESIGN_TASK.NeedReview].includes(taskStatus);

  return (
    <React.Fragment>
      <Popover
        activator={activator}
        onClose={togglePopover}
        active={popoverActive}
        preferredAlignment="right"
      >
        <Container>
          <ActionList items={state} />
        </Container>
      </Popover>
      {isEqual(currentItemId, task.id) && (
        <>
          <ViewDetailPolaris
            open={showModal && "viewDetail" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            filter={filter}
            onNotificationChange={onNotificationChange}
            toggleActive={toggleActive}
          />
          <UpdateTaskPolaris
            open={showModal && "updateTask" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
            toggleActive={toggleActive}
            refetch={refetch}
          />
          <UpdateTaskResource
            open={showModal && "taskResource" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
            toggleActive={toggleActive}
            refetch={refetch}
          />
          <UpdateToDoingPolaris
            open={showModal && "updateToDoing" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
            toggleActive={toggleActive}
            refetch={refetch}
          />
          <StartActionPolaris
            open={showModal && "startNow" === currentAction}
            toggleShowModal={toggleShowModal}
            idsChecked={[task.id]}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            onCompleted={() => {
              if (refetch) {
                refetch();
              }
            }}
          />
          <DenyActionPolaris
            open={showModal && "deny" === currentAction}
            toggleShowModal={toggleShowModal}
            idsChecked={[task.id]}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            onCompleted={() => {
              if (refetch) {
                refetch();
              }
            }}
          />
          <ReAssignPolaris
            open={showModal && "reAssign" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          {showModal && "config" === currentAction && (
            <ConfigDesign
              open={showModal && "config" === currentAction}
              toggleShowModal={toggleShowModal}
              toggleActive={toggleActive}
              onNotificationChange={onNotificationChange}
              refetch={refetch}
              task={task}
            />
          )}
          <CancelPolaris
            open={showModal && "cancel" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <AcceptDesignPolaris
            open={showModal && "acceptDesign" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <PassToNeedReview
            open={showModal && "passToNeedReview" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <MarkAsTaskConfigurable
            open={showModal && "markAsTaskConfigurable" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <RemoveTaskFromConfigurable
            open={showModal && "removeTaskFromConfigurable" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <PassToAwaitingBuyerConfirm
            open={showModal && "passToAwaitingBuyerConfirm" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            task={task}
          />
          <AwaitApproval
            open={showModal && "awaitApproval" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            task={task}
            refetch={refetch}
          />
          <DeleteNote
            open={showModal && "deleteNote" === currentAction}
            toggleShowModal={toggleShowModal}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            task={task}
            refetch={refetch}
          />
          <CompareDesignPolaris
            open={showModal && "compareDesignUU" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            status={taskStatus}
            modalTitle={
              [STATUS_DESIGN_TASK.Done].includes(taskStatus)
                ? "Task design files"
                : "Compare design task"
            }
            hasCompareDesign
            hasAcceptDesign={isNeedReview}
            hasRejectDesign={isNeedReview}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <CompareDesignPolaris
            open={showModal && "compareDesignCancel" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            status={taskStatus}
            modalTitle="Compare design task"
          />
          <CompareDesignPolaris
            open={showModal && "compareDesignRejected" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            status={taskStatus}
            modalTitle="View rejected design"
            isViewRejectedDesign
            hasCompareDesign
          />
          <CompareDesignPolaris
            open={showModal && "compareDesignDoing" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            status={taskStatus}
            modalTitle="View Design"
            isViewRejectedDesign
          />
          <DesignResourceFilesPolaris
            open={showModal && "designResource" === currentAction}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            toggleShowModal={toggleShowModal}
            task={task}
            refetch={refetch}
          />
          <RejectDesignPolaris
            open={showModal && "rejectDesign" === currentAction}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            toggleShowModal={toggleShowModal}
            task={task}
            refetch={refetch}
          />
          <UploadDesignByDesignerPolaris
            open={showModal && "uploadDesignDoing" === currentAction}
            toggleActive={toggleActive}
            toggleShowModal={toggleShowModal}
            task={task}
            user={user}
            isDesigner={isDesigner}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <UploadDesignByDesignerPolaris
            open={showModal && "reUploadDesign" === currentAction}
            toggleActive={toggleActive}
            toggleShowModal={toggleShowModal}
            task={task}
            user={user}
            isDesigner={isDesigner}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
            reUpload
          />
          <UploadDesignByLeadPolaris
            open={showModal && "uploadDesignByLead" === currentAction}
            toggleActive={toggleActive}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <UploadDesignByLeadPolaris
            open={showModal && "uploadDesignDone" === currentAction}
            toggleActive={toggleActive}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <GetSimilarProduct
            open={showModal && "similarProduct" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            onNotificationChange={onNotificationChange}
          />
          <MarkPSDinNas
            open={showModal && "markPSD" === currentAction}
            toggleShowModal={toggleShowModal}
            task={task}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <MarkPBError
            open={showModal && "markFBError" === currentAction}
            toggleShowModal={toggleShowModal}
            product={product}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <RemoveMarkPBError
            open={showModal && "removeMarkFBError" === currentAction}
            toggleShowModal={toggleShowModal}
            product={product}
            toggleActive={toggleActive}
            onNotificationChange={onNotificationChange}
            refetch={refetch}
          />
          <SuspenseComp fallback={null}>
            <RejectArtistTask
              open={showModal && "rejectAristTask" === currentAction}
              onClose={toggleShowModal}
              task={task}
              refetch={refetch}
            />
          </SuspenseComp>
        </>
      )}
    </React.Fragment>
  );
};

const checkDesignerBot = (assignee) => {
  if (objectInvalid(assignee)) return false;
  const { firstName, lastName } = assignee;
  const reg = new RegExp("designerbot", "gi");

  const assigneeStr = [firstName, lastName]
    .filter(Boolean)
    .map((i) => i.trim())
    .join("");

  return !!assigneeStr.match(reg);
};

// reject artist task
function checkRejectAT(task) {
  const artistTaskID = get(task, "artistTaskID");
  const label = splitTaskLabel(get(task, "label"));
  const isAwaiting = isTaskLabel(label, TASK_LABEL.AwaitingArtistFix);

  if (!artistTaskID || isAwaiting) return false;
  return true;
}
